.dycDetalle{
  display: flex;
  flex-wrap: wrap;
  position: relative;
  &Column{
    margin-right: 32px;
    width: calc(50% - 16px);
    &:last-of-type{
      margin-right: 0;
    }
    @media all and (max-width: $media-md) {
      width: 100%;
      margin-right: 0;
    }
  }
  &Image{
    display: block;
    border-bottom: 2px solid $border;
    position: relative;
    &Img{
      width: 100%;
      display: block;
      max-height: 785px;
      margin: 0 auto;
      position: relative;
    }
    &Icon{
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-size: 20px;
      z-index: 1;
      color:  $border;
    }
    &Container{
      background: url(https://d2ooufp7gt4q45.cloudfront.net/assets/vendor/shared/1629939351998.gif) no-repeat center;
      background-size: 10%;
      min-height: 320px;
      position: relative;
      display: flex;
      justify-content: center;
    }
  }
  &Description{
    margin-bottom: 12px;
    color: $color-txt;
    &Title{
      font-family: $font-primary;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      background: $ligth-dark;
      box-sizing: border-box;
      position: relative;
      cursor: pointer;
      padding: .75em 1em;
      @media all and (max-width: $media-md) {
        padding: .5em;
      }
      svg{
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }
    &Content{
      line-height: 1.2;
      font-family: $font-second;
      padding: 10px;
      box-sizing: border-box;
      border: 1px solid $border;
      display: none;
      a{
        color: $pink;
        text-decoration: underline;
        }
      p{
        display: block;
        margin-bottom: .5em;
        line-height: 1.3;
      }
      ul{
        padding-left: 1em;
        li{
          position: relative;
          margin-bottom: .25em;
          line-height: 1.3;
          &:before{
            content: "";
            position: absolute;
            top: 0.5em;
            left: -.5em;
            background: black;
            width: 3px;
            height: 3px;
          }
        }
      }
      i{
        font-style: italic;
      }
      strong, b{
        font-weight: bold;
      }
      u{
        text-decoration: underline;
      }
      i{
        font-style: italic;
      }
    }
    &-show{
      .dycDetalleDescriptionContent{
        display: block;
      }
      svg{
        transform: rotate(180deg) translateY(50%);
      }
    }
  }
  &Thumbs{
    display: flex;
    flex-wrap: wrap;
    li{
      cursor: pointer;
      border: 2px solid $border;
      margin-right: 18px;
      img{
        width: auto;
        height: 62px;
        display: block;
        padding: 6px 12px;
      }
    }
    &-video{
      position: relative;
      &:before{
        background: white;
        opacity: .75;
        width: 100%;
        height: 100%;
        position: absolute;
        content: '';
      }
      svg{
        animation: detalle-cresciendo 1s alternate infinite ease-in;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        display: block;
        position: absolute;
        color: $pink;
        font-size: 32px;
      }
    }
  }
  &Title{
    font-weight: bold;
    font-size: 26px;
    font-family: $font-primary;
    text-transform: uppercase;
    margin-bottom: 1em;
    position: relative;
    &:after{
      content: "";
      width: 1em;
      height: 5px;
      background: $pink;
      position: absolute;
      bottom: -.5em;
      left: 0;
    }
    @media all and (max-width: $media-md) {
      text-align: center;
    }
    &Mobile{
      display: none;
      text-align: center;
      @media all and (max-width: $media-md) {
        display: block!important;
      }
    }
  }
  &Code{
    font-size: 14px;
    margin-bottom: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    @media all and (max-width: $media-md) {
      text-align: center;
    }
  }
  &Price{
    color: $pink;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 18px;
    position: relative;
    font-family: $font-second;
    @media all and (max-width: $media-md) {
      text-align: center;
    }
    &:before{
      position: relative;
      font-size: 80%;
      content: '$';
      margin-right: .2em;
    }
    &:after{
      position: relative;
      content: 'MXN';
      font-size: 80%;
      margin-left: .2em;
    }
    &Low{
      text-decoration: line-through;
      font-weight: normal;
      color: $color-txt;
      position: relative;
      font-size: 120%;
    }
  }
  &Countdown{
    font-size: 16px;
    line-height: 1.2;
    b{
      font-weight: bold;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 3px;
        background: $pink;
      }
    }
    span{
      display: block;
      margin-bottom: .4em;
    }
  }
  &Steps{
    &Title{
      font-family: $font-primary;
      font-weight: bold;
      font-size: 18px;
      color: $color-txt;
      display: block;
      margin-bottom: 1em;
      text-transform: uppercase;
      line-height: 1.25;
    }
    &Warning{
      background: $green;
      color: white;
      font-size: 13px;
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      margin-bottom: 12px;
      svg{
        margin-right: 1em;
        path{
          stroke: white;
        }
      }
      small{
        font-size: 80%;
      }
    }
  }
  &Days{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    li{
      border: 1px solid $border;
      padding: 10px 5px;
      box-sizing: border-box;
      font-size: 16px;
      cursor: pointer;
      margin-right: 12px;
      margin-bottom: 12px;
      width: calc(20% - 10px);
      @media all and (max-width: $media-md) {
        width: calc(33% - 8px);
        padding: 12px 10px;
      }
      &:hover,
      &.active{
        background: $pink;
        color: white;
      }
      &:nth-child(5n){
        @media all and (min-width: $media-md) {
          margin-right: 0;
        }
      }
      &:nth-child(3n){
        @media all and (max-width: $media-md) {
          margin-right: 0;
        }
      }
      &.vip{
        font-size: 15px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        b{
          margin: 0 auto;
          font-weight: bold;
          font-size: 22px;
        }
      }
      span, small{
        display: block;
        width: 100%;
        text-align: center;
      }
      span{
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: .5em;
      }
      small{
        font-size: 80%;
      }
      &.disableDay{
        cursor: not-allowed;
        background: white!important;
        color: lighten($color-txt, 50)!important;
      }
    }
    &-Hours{
      span{
        font-size: 15px;
      }
    }
  }
  &Datepicker{
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid $border;
    padding: 1em;
    background: white;
    width: 100%;
    &.active,
    &:hover{
      background: $pink;
      color: white;
    }
    svg{
      margin-right: .5em;
      bottom: -2px;
      position: relative;
    }
    span{
      box-sizing: border-box;
    }
  }
  &Date{
    display: block;
    margin: .5em 0;
  }
  &Warning{
    background: $red-warning;
    padding: 1em;
    font-size: 16px;
    text-align: center;
    line-height: 1.25;
    width: 100%;
    b{
      display: block;
      font-weight: bolder;
      text-decoration: underline;
    }
    &-info{
        background: $ligth-dark;
    }
  }
  &Discount{
    background: $pink;
    border-radius: 50%;
    height: 3em;
    width: 3em;
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &Townselected{
    cursor: pointer;
    border: 1px solid $border;
    padding: 1em;
    box-sizing: border-box;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &City{
      padding-right: 1em;
      box-sizing: border-box;
      font-style: italic;
      color: $pink;
    }
    &Action{
      font-size: 85%;
      color: $pink;
      display: flex;
      align-items: center;
      svg{
        font-size: 24px;
      }
    }
  }
  &Vip{
    li{
      padding: 16px;
      box-sizing: border-box;
      border-bottom: 1px solid $border;
      cursor: pointer;
      display: flex;
      justify-content: space-around;
      align-content: center;
      &:hover{
        color: white;
        background: $pink;
      }
    }
    &Label{
      font-size: 15px;
      &:before{
        content: 'Entrega: ';
      }
    }
    &Price{
      font-weight: bolder;
      &:before{
        content: '$';
        font-weight: normal;
      }
    }
  }
  &New{
    background: black;
    position: absolute;
    padding: 0.5em .7em;
    top: 0;
    left: 0;
    display: block;
    font-family: $font-second;
    color: white;
    z-index: 2;
    font-size: 15px;
    box-shadow: 0 1px 3px #e2e2e2;
  }
  &Available{
    //background: linear-gradient(to left, #ED213A, #93291E);
    background: linear-gradient(to right, $pink, lighten($pink, 30));
    position: absolute;
    padding: 0.5em .7em;
    bottom: 5px;
    left: 0;
    display: block;
    font-family: $font-second;
    color: white;
    z-index: 2;
    font-size: 15px;
    box-shadow: none;
    text-transform: uppercase;
  }
  &Exhausted{
    background: black;
    position: absolute;
    padding: 0.5em 1em;
    top: 0;
    right: 0;
    display: block;
    font-family: $font-second;
    color: white;
    z-index: 4;
    font-size: 15px;
    box-shadow: 0 1px 2px black;
  }

  .react-datepicker-wrapper{
    width: 100%;
  }
}

.dycDropdown{
  overflow: hidden;
  li{
    display: block;
    color: $color-txt;
    font-size: 17px;
    box-sizing: border-box;
    margin: 0;
    position: relative;
    background: white;
    padding: 1em .5em;
    border: 1px solid $border;
    border-bottom: 0;
    cursor: pointer;
    svg{
      display: block;
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
    }

    &:hover{
      background: $pink;
      color: white;
    }

    &:last-of-type{
      border-bottom: 1px solid $border;
    }
  }
  a{
    text-decoration: none;
  }
  &Back{
    background: $ligth-dark!important;
    padding-left: 1.5em!important;
    svg{
      left: 0;
    }
  }
}

@keyframes detalle-cresciendo {
  //0%   {transform: scale(.8);}
  100% {transform: scale(1.2) translate(-40%,-40%);}
}