.dycModal{
  max-width: 720px;
  margin: auto auto;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  background: white;
  border:1px solid $border;
  font-family: $font-second;
  &-video{
    max-width: 420px;
  }
  &Header{
    border-bottom: 1px solid $border;
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    font-family: $font-primary;
    align-items: center;
    h1{
      font-weight: bold;
      text-transform: uppercase;
      font-size: 18px;
    }
  }
  &Body{
    padding: 12px;
    box-sizing: border-box;
    overflow-y: scroll;
    max-height: 75vh;
  }
  &Description{
    font-family: $font-second;
    line-height: 1.3;
  }
  &Close{
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 22px;
    background: $pink;
    svg path{
      stroke: white;
    }
  }
  &Overlay{
    background: rgba(white, .85);
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

}