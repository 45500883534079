.whatsapp{
  position: fixed;
  bottom: 25px;
  right: 25px;
  font-size: 42px;
  z-index: 10;
  a{
    background: #00a70e;
    border-radius: 50%;
    box-sizing: border-box;
    overflow: hidden;
    color: white;
    padding: .25em .25em;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 3px $color-txt-lighten;
  }
  svg{
    margin: 0;
  }
}