.paypal{
  margin: 42px 0;
  h1{
    font-family: $font-primary;
    font-size: 32px;
    text-transform: uppercase;
    margin-bottom: 32px;
  }
  img{
    display: block;
    width: 320px;
    margin: 0 auto 32px;
  }
  p{
    font-size: 16px;
    font-family: $font-second;
    line-height: 1.5;
    margin-bottom: 1em;
  }
}