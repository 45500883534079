@mixin hoverThumb {
  .stageThumbContainer:before{
    background: rgb($pink, .75);
  }
  .stageThumbContainer h5{
    opacity: 1;
  }
}

.stage{
  &Item{
    min-height: 415px;
    position: relative;
    @media all and (max-width: $media-sm){
      min-height: 242px;
    }
    &Container{
      min-height: 100%;
      min-width: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: center;
      font-family: $font-second;
      @media all and (max-width: $media-sm){
      background-position: 27% top;
      }
      &-black{
        .stageTitle,
        .stageDescription,
        .stageButton{
          color: white;
        }
      }
    }
    .button{
      margin: 0!important;
      border-color: $color-txt;
      color: $color-txt;
      @media all and (max-width: $media-sm){
        display: none;
      }
    }
    a{
      color: $color-txt;
      text-decoration: none;
    }
  }
  &Wrapper{
    min-height: 418px;
    margin: 0 auto;
    max-width: 1010px;
    display: block;
    @media all and (max-width: $media-sm){
      min-height: 245px;
    }
    >div{
      padding-top: 72px;
      max-width: 335px;
      box-sizing: border-box;
      padding-left: 12px;
      @media all and (max-width: $media-sm){
        max-width: 252px;
        padding-top: 32px;
        padding-left: 10px;
      }
    }
  }
  &Title{
    font-family: $font-primary;
    font-size: 42px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 18px;
    @media all and (max-width: $media-sm){
      font-size: 24px;
    }
  }
  &Description{
    font-size: 18px;
    margin-bottom: 12px;
    line-height: 1.2;
    @media all and (max-width: $media-sm){
      font-size: 14px;
    }
  }
  &Thumb{
    height: 120px;
    position: relative;
    border-right: 1px solid white;
    box-sizing: border-box;
    cursor: pointer;
    &:before{
      content: '';
      position: absolute;
      background: $pink;
      width: 100%;
      height: 10px;
      top: 0;
      left: 0;
      z-index: 2;
    }
    &Container{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: top center;
      background-repeat: no-repeat;
      background-size: auto 110%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before{
        content: '';
        background: rgb($pink, 0);
        transition: all .2s ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        width: 100%;
        height: 100%;
      }
      h5{
        width: 70%;
        font-size: 18px;
        font-weight: bold;
        font-family: $font-primary;
        color: white;
        line-height: 1.25;
        position: relative;
        z-index: 4;
        text-transform: uppercase;
        transition: all .2s ease-in-out;
        opacity: 0;
      }
    }
    &:hover{
      @include hoverThumb;
    }
  }
  &Button{
    color: $color-txt;
    position: relative;
    svg{
      top: 3px;
      position: relative;
    }
  }
}

.slick-current{
  @include hoverThumb;
}

