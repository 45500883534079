.header {
  background: white;
  position: relative;
  &Wrapper {
    padding: 0 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-txt;
    font-family: $font-second;
    margin-bottom: 12px;
    @media all and (max-width: $media-md) {
      align-items: center;
    }
    @media all and (max-width: $media-sm){
      padding: 0 10px;
    }
  }
  &Logo {
    padding: 10px 0;
    box-sizing: border-box;
    @media all and (max-width: $media-sm) {
      padding: 4px 0 0 0;
    }
    a{
      display: block;
    }
      img{
        @media all and (max-width: $media-sm) {
          height: 72px;
        }
    }
  }
  &Menu {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.5;

    @media all and (max-width: $media-md){
      display: none;
    }

    li {
      padding: 10px;
      line-height: 1;
      text-transform: uppercase;
      font-size: 15px;
      cursor: pointer;
      position: relative;

      a{
        color: $color-txt;
        text-decoration: none;
      }

      &:after {
        content: '';
        width: 100%;
        height: 3px;
        background: white;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &:hover {
        &:after {
          background: $color-txt;
        }
      }
    }
  }
  &Principal {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    li {
      display: block;
      position: relative;
      a {
        padding: 12px 16px 6px;
        box-sizing: border-box;
        font-size: 32px;
        display: block;
        color: $color-txt;
        &:hover {
          color: $pink;
        }
        @media all and (max-width: $media-sm){
          padding: 10px;
          font-size: 28px;
        }
      }
    }
  }
  &Submenu {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    width: 100%;
    min-width: 100vw;
    background: white;
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;
    box-sizing: border-box;
    font-family: $font-second;

    &Container {
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      padding: 24px 0;
      box-sizing: border-box;

      li {
        width: 33.3%;
        max-width: 33.3%;
        flex-grow: 1;
        padding: 12px;
        box-sizing: border-box;
        border-right: 1px solid $border;

        &:nth-child(3n) {
          border-color: white;
        }

        a {
          display: block;
          color: $color-txt;
          text-decoration: none;
          line-height: 1.2;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &Close {
      color: $pink;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 18px;
      box-sizing: border-box;

      > div {
        cursor: pointer;
        padding: 6px 12px;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      span {
        margin-right: .5em;
      }
    }
  }
  &Mobile{
    display: none;
    @media all and (max-width: $media-sm){
      display: block;
    }
    &Button{
      font-size: 28px;
      padding: 10px 18px;
      box-sizing: border-box;
    }
  }
  &Bagde{
    background: $pink;
    color: white;
    font-size: 13px;
    box-sizing: border-box;
    position: absolute;
    bottom: 2px;
    right: 0;
    text-align: center;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5;
    border-radius: 50%;
  }
}

.hamburguer{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  height: 100vh;
  width: 100vw;
  background: white;
  z-index: 99;
  &-open{
    display: block;
  }
  &Body{
    overflow-y: scroll;
    max-height: 90vh;
  }
  &Head{
    padding: 8px 10px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 24px;
    img{
      display: block;
      margin: 0 auto;
      height: 62px;
    }
  }
  &Close{
    font-size: 28px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    z-index: 2;
  }
  &Content{
    padding-bottom: 24px;
    li{

      box-sizing: border-box;
      position: relative;
      text-transform: uppercase;
      border-top: 1px solid $border;
      >a,
      >span{
        padding: 12px 18px;
        display: block;
        font-size: 18px;
      }
      &:last-of-type{
        border-bottom: 1px solid $border;
      }
      span{
        font-size: 20px;
        font-family: $font-second;
        display: block;
      }
      svg{
        position: absolute;
        top: .5em;
        right: 24px;
        font-size: 22px;

      }
      ul{
        margin-top: 24px;
        font-family: $font-second;
        display: none;
        li{
          text-transform: none;
        }
        &.active{
          padding-left: 18px;
          margin-top: 0;
          display: block;
          li{
            &:last-of-type{
              border-bottom: 0;
            }
          }
        }
      }
    }
    a{
      text-decoration: none;
      color: $color-txt;
    }
    &-second{
      margin-top: 18px;
      li{
        text-transform: capitalize;
        font-family: $font-primary;
      }
    }
  }
}
