.input {
  font-family: $font-second;
  &Item {
    margin-bottom: 32px;
    display: block;
    >div{
      border: 1px solid $border;
      padding: .25em .5em;
      font-size: 14px;
      position: relative;
    }
    label{
      position: absolute;
      background: white;
      top:-.75em;
      left: .75em;
      font-size: 13px;
      color: $color-txt;
      padding: .1em .5em;
      font-family: $font-second;
    }
    input,
    textarea,
    select{
      border: 0;
      background: white;
      width: 100%;
      height: 100%;
      font-size: 16px;
      padding: 1em 1.5em;
      box-sizing: border-box;
      color: $color-txt;
      font-family: $font-second;
    }
    textarea{
      min-height: 5em;
    }
    &-error{
      >div{
        border-bottom-color: $red;
        border-bottom-width: 2px;
      }
    }
    &Toogle{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      width: 24px;
      cursor: pointer;
    }
  }
  &Error{
    font-size: 13px;
    color: $red;
    display: block;
    text-align: left;
    padding: .5em 0;
    box-sizing: border-box;
    font-family: $font-second;
    font-weight: normal;
    &-warning{
      color: #0543ff;
    }
  }
}
