.checkout{
  &Container{
    max-width: $container;
    display: block;
    margin: 0 auto;
    @media all and (max-width: $media-sm){
      box-sizing: border-box;
      padding: 0 10px;
    }
  }
  &Header{
    display: flex;
    margin-bottom: 23px;
    @media all and (max-width: $media-sm){
      justify-content: center;
    }
    img{
      display: block;
      height: 92px;
    }
  }
  &Timeline{
   font-family: $font-second;
    ul{
      border-bottom: 1px solid $border;
      display: flex;
      padding-bottom: 12px;
      li{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 22px;
        position: relative;
        @media all and (max-width: $media-sm){
          margin-right: 12px;
        }
        &.active{
          &:after {
            content: '';
            position: absolute;
            bottom: -12px;
            width: 100%;
            height: 2px;
            left: 0;
            background: black;
          }
        }
        &.standby{
          opacity: .5;
        }
      }
      span{
        background: black;
        color: white;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        display: block;
        text-align: center;
        line-height: 32px;
        font-weight: bold;
        margin-right: 6px;
        @media all and (max-width: $media-sm){
          min-width: 24px;
          width: 24px;
          height: 24px;
          line-height: 24px;
          font-size: 80%;
        }
      }
      p{
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
        font-weight: bold;
        @media all and (max-width: $media-sm){
          font-size: 10px;
        }
      }
    }
  }
  &Form{
    &Item{
      width: 100%;
    }
    &Item-50{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      >div{
        width: 100%;
        @media all and (min-width: $media-md) {
          width: calc(50% - 6px);
          margin-right: 12px;
          &:nth-child(2n){
            margin-right: 0;
          }
        }
      }
    }
    button{
      margin-left: 0;
      margin-right: 0;
      @media all and (max-width: $media-sm){
        width: 100%;
      }
    }
  }
  &Arrive{
    font-family: $font-second;
    border: 1px solid $border;
    padding: 22px;
    box-sizing: border-box;
    position: relative;
    p{
      margin-bottom: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }
    &Column{
      display: flex;
      flex-wrap: wrap;
      max-width: 250px;
      >div{
        margin-right: 18px;
        span{
          display: block;
          margin-bottom: .25em;
        }
        svg{
          font-size: 18px;
        }
      }
    }
  }
  &Column{
    display: flex;
    flex-wrap: wrap;

    >section{
      width: calc(70% - 12px);
      margin-right: 24px;
      @media all and (max-width: $media-sm){
        margin-right: 0;
        width: 100%;
      }
      &:nth-child(2n){
        width: calc(30% - 12px);
        margin-right: 0;
        @media all and (max-width: $media-sm){
          margin-right: 0;
          width: 100%;
        }
      }
    }
  }
  &Method{
    &Description{
      font-family: $font-second;
      font-size: 14px;
      margin-bottom: 32px;
    }
    button{
      margin-right: 0;
      @media all and (max-width: $media-sm){
        width: 100%;
      }
    }
  }
  &Type{
    li{
      border-top: 1px solid $border;
      padding: 12px;
      box-sizing: border-box;
      //margin-bottom: 18px;
    }
    &Head{
      display: flex;
      align-items: center;
      font-family: $font-second;
      input{
        margin-right: 12px;
        width: 28px;
        height: 28px;
      }
      label{
        text-transform: uppercase;
        font-weight: bold;
        line-height: 1.2;
      }
      img{
        display: block;
        margin-left: auto;
        max-height: 32px;
        max-width: 76px;
      }
    }
    &Images{
      display: flex;
      margin-left: auto;
      img{
        margin-right: 6px;
        &:last-of-type{
          margin-right: 0;
        }
      }
    }
    &Card{
      max-width: 480px;
      box-sizing: border-box;
      margin: 24px auto;
      &Column{
        display: flex;
        flex-wrap: wrap;
        >section{
          width: calc(50% - 12px);
          box-sizing: border-box;
          &:nth-child(2n-1){
            margin-right: 24px;
          }
          .inputItem{
            margin-bottom: 0;
          }
        }
        select{
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  &Sucess{
    h1{
      font-weight: bold;
      font-family: $font-primary;
      font-size: 28px;
      text-transform: uppercase;
      margin-bottom: 1em;
    }
    p{
      font-size: 16px;
      margin-bottom: 2em;
      font-family: $font-second;
      line-height: 1.7;
    }
  }
}
