.cart{
  &Container{
    display: flex;
    flex-wrap: wrap;
  }
  &Column{
    width: calc(100% - 374px);
    margin-right: 24px;
    @media all and (max-width: $media-sm){
      margin-right: 0;
    }
    &:nth-child(2n){
      width: 350px;
      margin-right: 0;
    }
    @media all and (max-width: $media-sm){
      width: 100%;
    }
  }
  &Resume{
    border: 1px solid $border;
    padding: 24px 15px;
    box-sizing: border-box;
    margin-bottom: 18px;
    font-family: $font-second;
    li{
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: 16px;
      b{
        small{
          text-decoration: line-through;
          margin-right: .5em;
          opacity: .75;
        }
      }
    }
    span{
      text-transform: uppercase;
      small{
        text-transform: capitalize;
      }
    }
    &Title{
      font-weight: bold;
      font-family: $font-primary;
      text-transform: uppercase;
      margin-bottom: 24px!important;
    }
    &Shippingfree{
      font-family: $font-second;
      background: $green;
      color: white;
      font-size: 13px;
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      svg{
        margin-left: .5em;
        path{
          stroke: white;
        }
      }
    }
    &Container{
      @media all and (min-width: $media-md) {
        position: sticky;
        top: 10px;
      }
    }
  }
  &Item{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    box-sizing: border-box;
    border: 1px solid $border;
    padding: 18px 10px;
    margin-bottom: 12px;
    position: relative;
    &Column{
      width: 240px;
      margin-right: 24px;
      &:nth-child(2n){
        width: calc(100% - 264px);
        margin-right: 0;
      }
      @media all and (max-width: $media-sm) {
        width: 100%!important;
        margin-right: 0!important;
        text-align: center;
      }
      img{
        display: block;
        max-width: 100%;
        max-height: 162px;
        margin: 0 auto;
        @media all and (max-width: $media-sm) {
          margin-bottom: 18px;
        }
      }
      a{
        display: block;
      }
    }
    &Title{
      margin-bottom: 18px;
      font-size: 18px;
      font-family: $font-primary;
      text-transform: uppercase;
      width: 90%;
    }
    &Price{
      font-size: 18px;
      margin-bottom: 18px;
    }
    &Delete{
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      font-size: 18px;
      color: $pink;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 2px;
      background: white;
      &:hover{
        background: $pink;
        color: white;
      }
    }
    &Change{
      display: flex;
      @media all and (max-width: $media-sm) {
        margin: 0 auto;
      }
      li{
        box-sizing: border-box;
        padding: 6px;
      }
      svg{
        font-size: 18px;
      }
      &Number{
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;
        span{
          padding: 0 6px;
        }
      }
      &Hover{
        border-radius: 2px;
        overflow: hidden;
        cursor: pointer;
        background: $pink;
        color: white;
        &:hover{
          background: darken($pink, 10);
        }
      }
      &Container{
        @media all and (max-width: $media-sm) {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  &Empty{
    min-height: 450px;
    display: flex;
    align-items: center;
    >div{
      width: 100%;
    }
    svg{
      margin: 0 auto 24px;
      font-size: 72px;
      display: block;
    }
    h1, p {
      text-align: center;
    }
    p{
      line-height: 1.5;
    }
    &-user{
      min-height: auto;
    }
    &Image{
      width: 100%;
      max-width: 300px;
      display: block;
      padding: 0;
      margin: 25px auto;
    }
  }
  &Tooltip{
    background: $yellow;
    color: $color-txt;
    padding: 1em;
    font-size: 14px;
    box-sizing: border-box;
    text-align: center;
    display: block;
    position: relative;
    &:before{
      content: '';
      width: 18px;
      height: 18px;
      transform: rotate(45deg) translateY(50%);
      background: $yellow;
      position: absolute;
      top: -10px;
      left: 50%;
    }
  }
  &Discount{
    font-family: $font-second;
    &Input{
      position: relative;
      display: flex;
      button{
        width: 30%;
        background: $pink;
        color: white;
        border: 1px solid $border;
        box-sizing: border-box;
        &:disabled{
          background: white;
          color: $border;
          span{
            display: none;
          }
        }
      }
      input{
        width: 70%;
        border: 1px solid $border;
        padding: 12px 1em;
        font-size: 14px;
        border-right: 0;
        text-transform: uppercase;
        box-sizing: border-box;
      }
    }
    &Msg{
      position: relative;
      color: darken($red-warning, 25);
      padding: 16px;
      box-sizing: border-box;
      text-align: center;
      border: 1px solid darken($red-warning, 25);
      margin-bottom: 18px;
      font-size: 14px;
      &:after{
        content: '';
        position: absolute;
        background: white;
        border-left: 1px solid darken($red-warning, 25);
        border-bottom: 1px solid darken($red-warning, 25);
        bottom: -15px;
        left: 15%;
        width: 15px;
        height: 15px;
        transform: translateY(-40%) rotate(-45deg);
      }
    }
    &Applied{
      display: flex;
      position: relative;
      border: 1px solid $border;
      padding: 12px;
      box-sizing: border-box;
      span{
        display: block;
        padding: .5em;
      }
      b{
        font-weight: bold;
        margin-right: 1em;
      }
      button{
        background: white;
        border: 0;
      }
      svg{
        color: $pink;
        font-size: 28px;
        cursor: pointer;
        box-sizing: border-box;
      }
    }
  }
}
