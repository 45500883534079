@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&family=Raleway:wght@300;400;700&display=swap');

$font-primary: 'Raleway', sans-serif;
$font-second: 'Open Sans', sans-serif;

//colors
$border: #e2e2e2;
$color-txt: #333333;
$color-txt-lighten: lighten($color-txt, 25);
$ligth-dark: #e5e5e5;
$pink: #E20177;
$red: #ed4758;
$red-warning: #ffd0d0;
$yellow: #E7CE18;
$green: #56ab2f;

//Media queries
$media-sm: 640px;
$media-md: 1024px;

$container: 1110px;


.container{
  max-width: $container;
  width: 100%;
  display: block;
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: border-box;
}
main{
  display: block;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: $container;
  margin: 0 auto;
  font-family: $font-second;
}

#nprogress .bar {
  background: $pink !important;
}
#nprogress .spinner-icon {
  border-top-color: $pink;
  border-left-color: $pink;
}

a{
  text-decoration: underline;
  color: $pink;
}

.marginBottom{
  margin-bottom: 42px!important;
  @media all and (max-width: $media-sm){
    margin-bottom: 28px!important;
  }
  &-big{
    margin-bottom: 72px!important;
    @media all and (max-width: $media-sm){
      margin-bottom: 62px!important;
    }
  }
}
.inlineBlock{
  display: inline-block;
}
.react-toast-notifications__toast{
  font-family: $font-second;
}

.flex-center{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.flex-start{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.title{
  font-family: $font-primary;
  color: $color-txt;
  font-weight: bold;
  font-size: 32px;
  margin: 0 0 28px 0;
  text-transform: uppercase;
  &-second{
    font-size: 26px;
  }
}

@media all and (max-width: $media-sm){
  .hidden-sm{
    display: none!important;
  }
}

.alignRight{
  text-align: right;
  display: flex;
  justify-content: right;
}

.seo{
  &Title{
    font-size: 32px;
    font-family: $font-primary;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 .5em;
    text-align: center;
  }
  &Description{
    font-size: 16px;
    font-family: $font-second;
    text-align: center;
    line-height: 1.2;
  }
}


.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
  background-color: $pink!important;
}