.pagination{
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  align-items: center;
  &Next{
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: underline;
    font-family: $font-primary;
    letter-spacing: 1px;
    a{
      color: $pink;
      polyline{
        stroke: $pink;
      }
    }
  }
  &Resume{
    display: flex;
    align-items: center;
    span{
      margin: 0 1em;
    }
  }
  &Number{
    color: $color-txt;
    box-sizing: border-box;
    //padding: 1em;
  }
}