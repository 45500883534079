@import "reset";
@import "vars";
@import "../node_modules/slick-carousel/slick/slick.css";
@import "../node_modules/slick-carousel/slick/slick-theme.css";
@import "../node_modules/react-medium-image-zoom/dist/styles.css";
@import "../node_modules/plyr/dist/plyr.css";
@import "button";
@import "spinner";
@import "footer";
@import "newsletter";
@import "stage";
@import "header";
@import "404";
@import "legal";
@import "cuenta";
@import "input";
@import "unit-minimum";
@import "detalle";
@import "cart";
@import "pagination";
@import "question";
@import "contact";
@import "account";
@import "whatsapp";
@import "search";
@import "checkout";
@import "modal";
@import "complement";
@import "paypal";
@import "indice";
@import "msj";
@import "blog";