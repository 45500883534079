.search{
  &Form{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 620px;
  }
  input{
    border: 1px solid $border;
    font-size: 22px;
    padding: .5em 1em;
    width: 100%;
    margin-bottom: 18px;
  }
  span{
    color: $red;
    display: block;
    margin: 0 0 12px 0;
    text-align: left;
    width: 100%;
  }
  button{
    width: 100%;
    border:1px solid $pink;
    color: white;
    background: $pink;
    padding: 1em;
  }
  &Result{
    &Items{
      &Detail{
        width: 100%;
        border: 1px solid $border;
        margin-bottom: 24px;
        padding: 12px;
        box-sizing: border-box;
        img{
          display: block;
          max-height: 420px;
          max-width: 90%;
          margin-bottom: 12px;
        }
        h3{
          text-transform: uppercase;
          font-size: 18px;
          text-align: center;
          margin-bottom: 12px;
        }
        span{
          display: block;
          color: $pink;
          text-align: center;
        }
      }
    }
    &Empty{
      font-size: 24px;
      text-align: center;
      margin: 2em;
      display: block;

    }
  }
}