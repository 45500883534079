.question{
  &Item{
    border-bottom: 1px solid $border;
    padding: 10px 24px;
    box-sizing: border-box;
    font-family: $font-second;
    margin-bottom: 12px;
    p{
      margin-bottom: 12px;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 1.5;
    }
    span{
      line-height: 1.5;
      font-size: 16px;
      padding-left: 24px;
      display: block;
      text-align: left;
    }
    b{
      font-weight: bold;
    }
    a{
      color: $pink;
      text-decoration: underline;
    }
    ul{
      padding-top: 12px;
      li{
        margin-bottom: 12px;
        display: block;
        position: relative;
        padding-left: 1em;
        &:before{
          content: '';
          background: $color-txt;
          width: 4px;
          height: 4px;
          display: block;
          position: absolute;
          left: 0;
          top: .75em;
        }
      }
    }
  }
}