.dycIndice{
  &Title{

  }
  &Description{
    color: $color-txt;
    text-align: left;
    img{
      display: block;
      width: 100%;
      height: auto;
    }
    li{
      list-style: none;
      line-height: 1.5;
      &:before{
        content: '•';
        margin-right: 5px;
        color: $border;
      }
    }
    a{
      text-decoration: underline;
      color: $color-txt;
    }
    p{
      margin: 1em 0;
      line-height: 1.3;
      font-size: 19px;
    }
  }
}