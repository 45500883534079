.legal {
  max-width: 955px;
  margin: 0 auto 32px;
  font-family: $font-second;
  &Title {
    font-size: 28px;
    font-family: $font-primary;
    margin-bottom: 24px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.2;
  }

  &Body {

    p {
      text-align: justify;
      font-size: 16px;
      line-height: 1.75;
      margin: 0 0 2em;
      font-family: $font-second;
    }

    ul {
      margin-bottom: 1em;
      li {
        font-family: $font-second;
        padding-left: 1em;
        line-height: 1.4;
        position: relative;
        &:before{
          content: '';
          background: $color-txt;
          width: 3px;
          height: 3px;
          position: absolute;
          top: .7em;
          left: -1px;
        }
      }
    }
  }
}
