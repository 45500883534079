.account{
  &Wrapper{
    display: flex;
    flex-wrap: wrap;
  }
  &Column{
    width: calc(60% - 32px);
    margin-right: 32px;
    box-sizing: border-box;
    @media all and (max-width: $media-sm){
      width: 100%;
    }
    @media all and (min-width: $media-sm){
      &:nth-child(2n){
        width: calc(40% - 32px);
        margin-right: 0;
      }
    }

  }
  &Logout{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 24px;
    a{
      text-decoration: none;
    }
    svg{
      margin-left: 1em;
    }
  }
  &History{
    &Item{
      margin-bottom: 24px;
      border-bottom: 1px solid $border;
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      &Column{
        width: calc(80% - 24px);
        margin-right: 24px;
        border-right: 1px solid $border;
        box-sizing: border-box;
        &:nth-child(2n){
          margin-right:0;
          width:20%;
          border: 0;
        }
      }
      &Title{
        font-weight: bold;
        text-transform: uppercase;
        font-size: 24px;
        margin-bottom: .5em;
      }
      &Second{
        color: $color-txt-lighten;
        font-size: 16px;
        line-height: 1.5;
        &Action{
          cursor: pointer;
          color: $pink;
          text-decoration: underline;
          img{
            margin: 0 .25em;
            height: 1.5em;
            display: inline;
            line-height: 1.8;
          }
          &:hover{
            text-decoration: underline;
          }
        }
      }
      &Status{
        font-weight: bold;
        //color: $pink;
        font-size: 16px;
        display: block;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
  &Form{
    background: $ligth-dark;
    box-sizing: border-box;
    padding: 20px 10px;
  }
}
