.unidad{
  width: 100%;
  max-width: 420px;
  margin: 0;
  border: 1px solid $border;
  overflow: hidden;
  background: white;
  position: relative;
  font-family: $font-second;
  a{
    text-decoration: none;
    color: $color-txt;
  }
  &Image{
    display: block;
    position: relative;
    img{
      display: block;
      max-width: 100%;
      max-height: 420px;
      margin: 0 auto;
      position: relative;
      transition: all .5s ease-in-out;
    }
    &:before{
      content: '';
      background: rgba($pink, .5);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      opacity: 0;
      transition: all .5s ease-in-out;
      z-index: 1;
    }
    &:after{
      content: '';
      background: white;
      border-top: 3px solid $pink;
      transform: rotate(5deg);
      bottom: -32px;
      left: -2px;
      width: 110%;
      height: 42px;
      display: block;
      z-index: 1;
      position: absolute;
    }
  }
  &Info{
    z-index: 2;
    padding: 0 22px 22px;
    box-sizing: border-box;
    font-family: $font-second;
    position: relative;
      h3{
        font-family: $font-primary;
        font-weight: bold;
        font-size: 24px;
        line-height: 1.3;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: .5em;
      }
    > div{
      font-family: $font-second;
      font-size: 16px;
      line-height: 1.4;
      color: $color-txt;
      margin-bottom: 12px;
    }
    span{
      display: block;
      text-align: right;
      color: $pink;
      font-size: 22px;
      font-weight: bold;
    }
  }
  &New{
    position: absolute;
    top: 0;
    left: 0;
    background: black;
    color: white;
    padding: .5em .7em;
    font-size: 13px;
    z-index: 2;
    text-transform: uppercase;
    box-shadow: 0 1px 3px #e2e2e2;
  }
  &Available{
    position: absolute;
    bottom: 22px;
    left: 22px;
    //background: linear-gradient(to left, #ED213A, #93291E); // 14 feb
    background: linear-gradient(to right, $pink, lighten($pink, 30));
    color: white;
    padding: .5em .7em;
    font-size: 13px;
    z-index: 2;
    text-transform: uppercase;
    box-shadow: none;
  }
  &Exhausted{
    position: absolute;
    top: 0;
    right: 0;
    background: black;
    color: white;
    padding: .5em 1em;
    font-size: 13px;
    z-index: 4;
    box-shadow: 0 1px 2px black;
  }
  &Discount{
    position: absolute;
    top: 10px;
    right: 10px;
    background: $pink;
    border-radius: 50%;
    z-index: 3;
    color: white;
    height: 3em;
    width: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: normal;
    font-family: $font-second;
  }
  @media all and (min-width: $media-sm) {
    &:hover{
        .unidadImage:before {
          opacity: 1;
        }
        .unidadImage img {
          transform: scale(1.1);
        }
    }
  }
  &Price{
    position: relative;
    font-weight: bold;
    font-size: 18px;
    font-family: $font-second;
    &:before{
      content: "$";
      font-size: 80%;
      position: relative;
    }
    &:after{
      content: "MXN";
      font-size: 60%;
    }
  }
}




.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}
