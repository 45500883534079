.notFound{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-family: $font-second;
  margin: 0 auto;
  padding: 142px 10px;
  width: 80%;
  max-width: 720px;
  box-sizing: border-box;
  min-height: 100vh;
  &Container{
      transform: translateY(-25%);
  }
  svg{
    font-size: 92px;
    display: block;
    margin: 24px auto;
  }
  h1{
    font-family: $font-primary;
    text-align: center;
    display: block;
    font-size: 52px;
    font-weight: bold;
    margin-bottom: 42px;
  }
  p{
    text-align: justify;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 2em;
  }
  small{
    display: block;
    color: $pink;
    text-decoration: underline;
    margin-bottom: 24px;
    width: 100%;
    text-align: center;
    font-style: italic;
  }
  a{
    display: block;

  }
}
