.button{
  cursor: pointer;
  background: $pink;
  color: white;
  border: 0;
  padding: 0 2em;
  box-sizing: border-box;
  margin: 0 auto;
  display: block;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 50px;
  height: 50px;
  position: relative;
  font-family: $font-second;
  &:after{
    width: 3px;
    top: 3px;
    right: -4px;
    height: 100%;
    content: '';
    border-top: 1px solid $pink;
    border-right: 1px solid $pink;
    position: absolute;
  }
  &:before{
    width: 100%;
    bottom: -4px;
    left: 3px;
    height: 3px;
    content: '';
    border-bottom: 1px solid $pink;
    border-left: 1px solid $pink;
    position: absolute;
  }
  svg{
    line-height: 50px;
    color: white;
    path{
      stroke: white;
    }
  }
  &:disabled{
    cursor: not-allowed;
    background: rgb($pink, .5);
  }

  &-simple{
    background: transparent;
    color: $pink;
    border: 2px solid $pink;
    border-radius: 4em;
  }
  a{
    text-decoration: none;
  }
  &-large{
    width: 100%;
  }
  &-txtcenter{
    text-align: center;
  }
}
a.button{
  text-decoration: none;
}



.dycMoreProducts {
  display: -webkit-box;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  font-family: $font-primary;
  a{
    color: $color-txt;
    font-weight: bold;
    text-align: center;
    display: block;
    font-size: 15px;
    padding: 1em 2em;
    box-sizing: border-box;
    border-top: 3px solid $pink;
    position: relative;
    text-transform: uppercase;
    transition: all .5s linear;
    text-decoration: none;
    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      width: 100%;
      background: #E20177;
      -webkit-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      transform: rotate(3deg)
    }
  }
}



.dycMoreProducts a i {
  font-size: 1.25em;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

@media (min-width: 1024px) {
  .dycMoreProducts a:hover {
    color:#E20177
  }
}