.dycMsj{
    font-family: $font-second;
    margin-bottom: 12px;
    box-sizing: border-box;
    background: #ffb347;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ffcc33, #ffb347);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ffcc33, #ffb347); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    &Wrapper{
        color: $color-txt;
        text-align: center;
        font-size: 14px;
        padding-top: .75em;
        padding-bottom: .75em;
        box-sizing: border-box;
        line-height: 1.25;
    }
    a{
        color: $color-txt;
        text-decoration: none;
    }
    strong{
        font-weight: bold;
    }
    em{
        font-style: italic;
    }
    &Green{
        background: #56ab2f;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #a8e063, #56ab2f);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #a8e063, #56ab2f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        a,p,h1,h2,h3{
            color: $color-txt;
        }
    }
    &Red{
        background: #93291E;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to left, #ED213A, #93291E);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to left, #ED213A, #93291E); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        a,p,h1,h2,h3{
            color: white!important;
        }
    }
    &Pink{
        background: #b91d73;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #f953c6, #b91d73);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #f953c6, #b91d73); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        a,p,h1,h2,h3{
            color: white!important;
        }
    }
    &Blue{
        background: #00d2ff;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #3a7bd5, #00d2ff);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #3a7bd5, #00d2ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        a,p,h1,h2,h3{
            color: white!important;
        }
    }
    &Orange{
        background: #FF8008;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #FFC837, #FF8008);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #FFC837, #FF8008); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        a,p,h1,h2,h3{
            color: white!important;
        }
    }
    &Black{
        background: #232526;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        a,p,h1,h2,h3{
            color: white!important;
        }
    }
}
