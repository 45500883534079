.newsletter{
  background: $ligth-dark;
  padding: 24px 0;
  border-top: 1px solid $border;
  border-bottom: 1px solid $border;
  font-family: $font-second;
  &Form{
    max-width: 375px;
    margin: 0 auto;
    display: block;
    input{
      background: transparent;
      border:0;
      border-bottom: 1px solid $color-txt;
      width: 100%;
      max-width: 15em;
      display: block;
      font-family: $font-second;
      font-size: 16px;
      text-align: center;
      margin: 0 auto 12px;
    }
    button{

    }
  }
  &Loading{
    max-width: 15em;
    margin: 0 auto;
    p{
      color: $color-txt;
      padding: 1em 0;

    }
  }
  h5{
    font-family: $font-primary;
    font-size: 22px;
    margin: .5em 0 18px;
    font-weight: bold;
    text-align: center;
    display: block;
  }
  p{
    max-width: 375px;
    display: block;
    margin: 0 auto 18px;
    text-align: center;
    font-size: 14px;
    line-height: 1.25;
    font-family: $font-second;
  }
  &Submited{
    >p{
      font-size: 18px;
      color: $color-txt;
      line-height: 1.5;
      font-family: $font-second;
    }
    code{
      color: $pink;
      font-weight: bold;
      font-family: $font-primary;
    }
  }
  &Error{
    color: $red;
    text-align: center;
    font-size: 13px;
    margin: 0 0 1em;
    display: block;
  }
}
