.login{
    display: flex;
    @media all and (max-width: $media-md) {
      flex-wrap: wrap;
    }
  &Column{
    width: calc(50% - 32px);
    margin-right: 32px;
    &:nth-child(2n){
      margin-right: 0;
    }
    @media all and (max-width: $media-md) {
      margin-right: 0;
      width: 100%;
      padding-bottom: 42px;
      margin-bottom: 42px;
      border-bottom: 1px solid $border;
    }
  }
  &Title{
    font-size: 28px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: $font-primary;
    margin-bottom: 24px;
    display: block;
  }
  &Link{
    display: block;
    a{
      color: $pink;
      text-decoration: underline;
    }
  }
}


.registerSide{
  p{
    margin-bottom: 12px;
  }
  &List{
    font-family: $font-second;
    font-size: 16px;
    li{
      position: relative;
      display: block;
      line-height: 1.4;
      margin-bottom: 1em;
      padding-left: 2em;
      span{
        top:5px;
        left: 0;
        position: absolute;
        padding-right: .5em;
      }
    }
  }
}

.forgot{
    max-width: 520px;
  margin: 0 auto;
  h1{
    display: block;
    font-weight: bold;
    text-transform: uppercase;

  }
}



.compra{
  font-family: $font-second;
  &Header{
    display: flex;
    justify-content: center;
  }
  &Status{
    color: $pink;
    font-size: 28px;
    padding: 1em;
    width: 100%;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }
  &Title{
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 1em;
  }
  &Text{
    margin-bottom: 32px;
    color: $color-txt-lighten;
  }
  &Detail{
    margin: 0 0 42px;
    padding: 0;
      li{
        color: $color-txt;
        margin: 0 0 12px 0;
        padding: 8px;
        display: flex;
        border-bottom: 1px solid $border;
        justify-content: space-around;
        font-size: 18px;
        img{
          width: 350px;
        }
        p{
          width: 50%;
          padding-left: 32px;
          box-sizing: border-box;
        }
        small{
          padding-left: 1em;
          font-size: 80%;
        }
    }
  }
}