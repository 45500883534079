.contact{

  &Wrapper{
    display: flex;
    flex-wrap: wrap;
  }
  &Column{
    width: calc(50% - 32px);
    margin-right: 32px;
    min-height: 20px;
    &:nth-child(2n){
      margin-right: 0;
    }

    @media all and (max-width: $media-sm){
      width: 100%;
      margin-bottom: 24px;
      margin-right: 0!important;
    }

    img{
      display: block;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    ul{
      li{
        display: block;
        margin-bottom: 18px;
        line-height: 1.5;
        b{
          font-weight: bold;
          display: block;
          margin-bottom: .25em;
          text-transform: uppercase;
          font-size: 18px;
        }
      }
    }
  }

  &Form{
    //background: $ligth-dark;
    border: 1px solid $border;
    padding: 22px;
    box-sizing: border-box;
    h2{
      font-weight: bold;
      display: block;
      margin-bottom: 1em;
      text-transform: uppercase;
      font-size: 18px;
    }
    p{
      line-height: 1.5;
    }
  }
}