.dycComplement{
  &Options{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  &Carousel{
    width: calc(100% - 92px);
    box-sizing: border-box;
    //border: 1px solid $border;
    &Item{
      padding: 10px;
      box-sizing: border-box;
      margin-right: 12px;
      cursor: pointer;
      &:hover{
        p{
          text-decoration: underline;
        }
      }
      &Image{
        width: 100%;
        height: 120px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
      }
      a{
        text-decoration: none;
      }

      p{
        font-size: 12px;
        color: $color-txt;
        margin-bottom: 8px;
        height: 2.2em;
        line-height: 1.1;
        overflow: hidden;
        text-align: center;
      }
      span{
        text-align: center;
        color: $color-txt;
        font-weight: bold;
        display: block;
      }
    }
  }
  &Cta{
    box-sizing: border-box;
    width: 92px;
    cursor: pointer;
    >div{
      border-left: 1px solid $border;
      padding: 10px;
      box-sizing: border-box;
      height: 100%;
      display: flex;
      align-items: center;
    }
    svg{
      display: block;
      margin: 0 auto 12px;
      font-size: 24px;
    }
    span{
      font-size: 12px;
      display: block;
      text-align: center;
    }
  }
  &Modal{
    &Item{
      margin-bottom: 12px;
      width: 100%;
      &.active{
        .dycComplementModalContent{
          display: flex;
        }
      }
    }
    &Title{
      display: flex;
      background: lighten($ligth-dark, 5);
      width: 100%;
      align-items: center;
      cursor: pointer;
      margin-bottom: 12px;
      overflow: hidden;
     div{
        background: white;
       position: relative;
       padding: 4px 0;
       img{
         display: block;
         height: 28px;
         padding: 4px;
         z-index: 1;
         position: relative;
       }
       &:after{
         z-index: 0;
         content: '';
         background: white;
         height: 300%;
         width: 5em;
         display: block;
         right: -10px;
         top: -100%;
         position: absolute;
         transform: rotate(15deg);
       }
     }
      svg{
        margin: 0 12px 0 auto;
      }
      h3{
          font-family: $font-second;
        margin-left: 2em;
        line-height: 2.4;
        display: block;
        font-size: 18px;
      }
    }
    &Content{
      display: none;
      flex-wrap: wrap;
      &Img{
        border: 1px solid $border;
        width: 100%;
        height: 142px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin: 0 auto 12px;
      }
      li{
        cursor: pointer;
        width: calc(33% - 6px);
        margin: 0 12px 18px 0;
        @media all and (min-width: $media-sm) {
          &:hover{
            p{
              text-decoration: underline;
            }
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
        @media all and (max-width: $media-sm){
          width: calc(50% - 6px);
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
        p{
          text-align: center;
          font-size: 12px;
          margin-bottom: 6px;
        }
        span{
          font-weight: bold;
          display: block;
          text-align: center;
        }
      }
    }
  }
  &Detail{
    h5{
      font-size: 16px;
      text-align: center;
      padding: 0 10px;
      box-sizing: border-box;
      margin-bottom: 1em;
      text-transform: uppercase;
    }
    &Price{
      font-weight: bold;
      text-align: center;
      font-size: 16px;
      margin-bottom: 1em;
    }
    &Back{
      margin: 18px 0;
      text-align:center;
      span{
        text-decoration: underline;
        color: $color-txt-lighten;
        cursor: pointer;
        font-size: 13px;
      }
    }
    &Description{
      border: 1px solid $border;
      >span{
        padding: 10px;
        box-sizing: border-box;
        display: block;
        border-bottom: 1px solid $border;
        background: $ligth-dark;
        font-weight: bold;
        text-transform: uppercase;
      }
      >div{
        padding: 1em 10px;
        box-sizing: border-box;
      }
      p{
        line-height: 1.1;
        margin-bottom: .5em;
      }
      li{
        box-sizing: border-box;
        padding-left: 1em;
        position: relative;
        margin-bottom: .5em;
        &:before{
          content: '';
          position: absolute;
          left: .5em;
          top: .5em;
          background: black;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          display: block;
        }
      }
    }
    &Slider{
      width: 250px;
      margin: 0 auto 28px;
      &Image{
        width: 250px;
        height: 200px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  &Simple{
    border: 1px solid $border;
    &Container{
      box-sizing: border-box;
      padding: 12px 10px;
      border-bottom: 1px solid $border;
      > h3{
        margin-bottom: 0;
      }
    }
    &Title{
      font-family: $font-primary;
      font-weight: bolder;
      font-size: 16px;
      color: $color-txt;
      text-align: left;
      margin-bottom: .5em;
    }
    &Description{
      color: $color-txt;
      font-family: $font-second;
      font-size: 14px;
      text-align: left;
      line-height: 1.2;
      b{
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
