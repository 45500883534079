.blog{
  &Container{
    display: flex;
    flex-wrap: wrap;
  }
  &Item{
    width: calc(33% - 32px);
    margin-right: 32px;
    margin-bottom: 1em;
    &:nth-child(3n){
      margin-right: 0;
    }
    @media all and (max-width: $media-sm){
      width: 100%;
      margin-right: 0;
    }
    a{
      display: block;
      text-decoration: none;
    }
    img{
      width: 100%;
      min-height: 250px;
    }
    span{
      color: $color-txt-lighten;
      margin: .5em 0;
      display: block;
    }
    h3{
      font-size: 22px;
      color: $color-txt;
      line-height: 1.2;
      margin: 0 0 1em 0;
      font-weight: bold;
    }
    p{
      font-size: 16px;
      color: $color-txt-lighten;
      line-height: 1.2;
      text-align: justify;
    }
  }
  &Detail{
    font-family: $font-second;
    margin-bottom: 5em;
    &Description{
      line-height: 1.5;
      margin: 0 0 42px;
      padding-left: 2em;
      position: relative;
      font-size: 18px;
      color: $color-txt-lighten;
      &:before{
        width: 3px;
        background: $pink;
        height: 100%;
        position: absolute;
        top: 0;
        left: 1em;
        content: '';
      }
    }
    &Content{
      color: $color-txt;
      font-size: 17px;
      line-height: 1.4;
      p{
        margin-bottom: 1.25em;
        font-size: 17px;
      }
      img{
        display: block;
        margin: 1em auto;
        max-width: 80%;
        height: auto;
        @media all and (max-width: $media-sm) {
          max-width: 100%;
        }
      }
      ol, ul{
        padding-left: 1em;
        box-sizing: border-box;
      }
      ol{
        list-style: decimal;
        ::marker{
          font-weight: bold;
          font-size: 120%;
        }
      }
      i{
        font-style: italic;
      }
      b{
        font-weight: bold;
      }
    }
    &Stage{
      margin-bottom: 42px;
      img{
        max-height: 325px;
        display: block;
        margin: 1em auto;
        max-width: 100%;
      }
    }
  }
}