footer{
  border-top: 1px solid $border;
  margin-bottom: 24px;
}

.footer{
  display: flex;
  justify-content: space-between;
  padding: 16px 8px;
  box-sizing: border-box;
  flex-wrap: nowrap;

  @media all and (max-width: $media-sm){
    flex-wrap: wrap;
  }

  &Title{
    font-family: $font-primary;
    font-size: 17px;
    font-weight: bold;
    padding: 1em .25em;
    box-sizing: border-box;
    text-transform: uppercase;
  }
  &Column{
    font-family: $font-second;
    margin-bottom: 15px;
    @media all and (max-width: $media-sm){
      width: 100%;
      li{
        text-align: center;
      }
    }
  }
  &Copy{
    border-top:1px solid $border;
    font-family: $font-second;
    text-align: center;
    padding: 10px 0;
    font-size: 13px;
    box-sizing: border-box;
  }
  &Social{
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    a{
      padding: .5em 1em;
      box-sizing: border-box;
      font-size: 18px;
      &.ws{
        color: #00a70e!important;
      }
      &.fb{
        color: #3b5998!important;
      }
      &.tw{
        color: #1dcaff!important;
      }
      &.in{
        color: #a06900!important;
      }
    }
  }
  a{
    padding: .5em .25em;
    box-sizing: border-box;
    display: block;
    text-decoration: none;
    color: $color-txt;
  }
}
